@import '../../base.scss';

@keyframes exit-menu {
    0%{
        transform: translateX(320px);
    }
    100%{
        transform: translateX(0px);
    }
}

.main {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    right: 0;
    max-width: 768px;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $background-footer;
    padding: 25px 20px;
    z-index: 1000;
    animation: exit-menu 1s ease-out;
}


@media(max-width: 768px){

.main {
    visibility: visible;
    opacity: 1;
    }

.box{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 60px;
}

.button {
    background-image: url(../../image/Header/close.svg);
    background-position: center;
    background-repeat: no-repeat;
    padding: 0;
    margin: 0;
    border: none;
    background-color: transparent;
    transition: .5s;
    width: 30px;
    height: 30px;

    &:hover{
        cursor: pointer;
        transform: rotate(180deg);
        transition: all .5s;
    }
}

.logo{
    width: 200px;
    height: auto;
}

.nav{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.link{
    width: 100%;
    font-size: 18px;
    line-height: 1.2;
    font-weight: 500;
    text-decoration: none;
    color: $white;
    transition: 0.3s;
    position: relative;
    margin-top: 36px;
    &:first-child {
        margin: 0;
    }
    &::before{
        background: $element;
        position: absolute;
        content: '';
        bottom: -3px;
        width: 100px;
        height: 2px;
        transition: 0.3s;
        transform: scaleX(0);
    }
    &:hover:before{
        transform: scaleX(1);
    }
}
}