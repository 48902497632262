@import '../../base.scss';

.main{
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url(../../image/Practical/im.jpg);
    background-repeat: no-repeat;
    background-position: 50% center;
    background-size: cover;
    background-attachment: fixed;
    background-color: transparent;
    position: relative;
    padding: 0;
}

.overlay {
    max-width: 100%;
    width: 100%;
    background-color: #f7f2eb80;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 40px;
}

.container{
    @include main-column;
    padding: 40px 20px 0;
}

.box{
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    width: 100%;
}

.title{
    max-width: 100%;
    width: 100%;
    font-size: 45px;
    line-height: 1;
    text-transform: uppercase;
    color: #2e3342;
    font-weight: 600;
    text-align: center;
    margin: 0;
}

.box_image{
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    margin: 20px 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.image{
    max-width: 33%;
    width: 100%;
    height: auto;
    aspect-ratio: 4/3;
}

@media(max-width: 1280px){
    .title{
        font-size: 40px;
    }
}

@media(max-width: 1080px){
    .title{
        font-size: 35px;
    }
}

@media(max-width: 768px){
    .overlay {
        padding: 0 0 30px;
    }
    
    .container{
        padding: 30px 10px 0;
    }

    .title{
        font-size: 32px;
    }
}

@media(max-width: 580px){
    .title{
        font-size: 28px;
    }
}

@media(max-width: 425px){
    .overlay {
        padding: 0 0 25px;
    }
    
    .container{
        padding: 25px 10px 0;
    }

    .title{
        font-size: 24px;
    }
    .box_image{
        & :last-child{
            display: none;
        }
    }

    .image{
        max-width: 49%;
    }
}