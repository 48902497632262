@import '../../base.scss';

.main{
    @include main-column;
}

.box{
    box-sizing: border-box;
    max-width: 1440px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title{
    @include title;
    margin-bottom: 20px;
}

.list {
    max-width: 100%;
    width: 100%;
    display: grid;
    box-sizing: border-box;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.item {
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-radius: 15px;
    overflow: hidden;
}

.image {
    width: 100%;
    height: auto;
}

.text_box {
    position: absolute;
    box-sizing: border-box;
    background-color: $white;
    left: -1px;
    bottom: -1px;
    padding: 7px 10px;
    border-radius: 0 10px;

}

.text {
    line-height: 1.2;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    color: $text;
}



@media(max-width: 1280px){

    .list {
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    }
}

@media(max-width: 1080px){

    .list {
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    }
}

@media(max-width: 768px){
    .text {
        font-size: 12px;
    }
}

@media(max-width: 425px){
    .list {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }

}