@import '../../base.scss';

@keyframes wheel-rotation {
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}

:root {
    --swiper-pagination-color: #CD2122;
    --swiper-pagination-bullet-width: 15px; 
    --swiper-pagination-bullet-height: 15px;
    --swiper-pagination-bullet-inactive-color: #FFF;
    --swiper-pagination-bullet-inactive-opacity: 0.5;
    --swiper-pagination-bottom: 15px;
    --swiper-navigation-color: #FBECCD;
}

.main{
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
}

.title{
    @include title;
    z-index: 1;
}

.box{
    @include main-column;
    padding: 50px 0;
}

.slider{
    max-width: 100%;
    width: 100%;
    margin-top: 20px;
}

.cell{
    height: 100%;
    box-sizing: border-box;
    max-width: 100%;
    width: 100%;
    padding: 10px;
    background-color: $background;
    border-radius: 6px;
}

.item{
    box-sizing: border-box;
    max-width: 100%;
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 6px;
    border: 1px solid #F2F2F2;
}

.subtitle{
    font-size: 24px;
    line-height: 1.5;
    color: $title;
    font-weight: 700;
    margin: 0;
    text-align: center;
}

.comment{
    font-size: 20px;
    line-height: 1.5;
    color: $title;
}

.text{
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
    margin: 0 0 10px;
    color: $text;
}

.image{
    width: 150px;
}

@media(max-width: 768px){

    .box{
        padding: 40px 0;
    }

    .text{
        font-size: 14px;
        line-height: 1.3;
    }

    .item{
        padding: 20px 10px;
    }
}  

@media(max-width: 425px){

    .title{
        max-width: 320px;
    }

    .box{
        margin-top: 50px;
        padding: 30px 0;
    }
}
