@import '../../base.scss';


.main{
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
    overflow: hidden;
}

.container{
    @include main-column;
}

.title{
    @include title;
    margin-bottom: 30px;
}

.list{
    max-width: 1400px;
    width: 100%;
    display: grid;
    box-sizing: border-box;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 20px;
}

.item{
    min-width: 230px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0 10px rgba( $background-footer, .4 );
    padding: 20px 10px;
    position: relative;
    z-index: 1;
    color: $text;
    border-radius: 15px;

    &:hover{
        cursor: pointer;
        transform:scale(1.05);
        transition: .5s;
    }
}

.box_image{
    box-sizing: border-box;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: $element;
    box-shadow: 0px 0px 8px 4px rgba($element, 0.25), 0px 0px 4px 4px rgba($element, 0.25);
}

.icon{
    width: 50px;
    height: 50px;
}

.item_title{
    font-weight: 700;
    font-size: 20px;
    line-height: 1.2;
    text-align: center;
    margin: 20px 0 10px;
    color: #939398;
    text-transform: uppercase;
}

.item_text{
    color: $title;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
    margin: 0;
    text-align: center;
}


.button{
    @include button;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    max-width: 350px;
    width: 100%;
    height: 50px;
    margin: 30px 0 0;
}

.image{
    width: 350px;
    height: auto;
    position: absolute;
    bottom: 0;
    right: 1%;
}


@media(max-width: 768px){

    .list{
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }

    .image{
        width: 250px;
    }

    .item_title{
        font-size: 18px;
    }
    
    .item_text{
        font-size: 14px;
    }
}