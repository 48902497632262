@import '../../base.scss';

.main{
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 40px;
}

.container{
    @include main-column;
    padding: 40px 20px 0;
}

.box{
    box-sizing: border-box;
    max-width: 1440px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title{
    @include title;
    margin-bottom: 20px;
}

.slider{
    max-width: 100%;
    width: 100%;
    margin-top: 20px;
}

.item {
    box-sizing: border-box;
    max-width: 450px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 16px;
    overflow: hidden;
    position: relative;
}

.item_info {
    background: rgba($black, 0.3);
    backdrop-filter: blur(5px);
    box-sizing: border-box;
    position: absolute;
    padding: 5px;
    bottom: 10px;
    right: 10px;
    pointer-events: none;
    border-radius: 8px;
}

.image{
    max-width: 100%;
    width: 100%;
    height: auto;
    border-radius: 16px;

    &:hover{
        cursor: pointer;
    }
}

.item_title {
    font-weight: 500;
    font-size: 18px;
    line-height: 1.2;
    color: $white;
    margin: 0;
}

@media(max-width: 1080px){
    .item_title {
        font-size: 16px;
    }
}

@media(max-width: 768px){
    .main{
        padding: 0 0 30px;
    }

    .container{
        padding: 30px 10px 0;
    }
}

@media(max-width: 425px){
    .main{
        padding: 0 0 25px;
    }

    .container{
        padding: 25px 10px 0;
    }
}



